import { render, staticRenderFns } from "./safe.vue?vue&type=template&id=e21f5992&scoped=true"
import script from "./safe.vue?vue&type=script&lang=js"
export * from "./safe.vue?vue&type=script&lang=js"
import style0 from "./safe.vue?vue&type=style&index=0&id=e21f5992&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e21f5992",
  null
  
)

export default component.exports