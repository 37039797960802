import { render, staticRenderFns } from "./teamcenter.vue?vue&type=template&id=0a74e654&scoped=true"
import script from "./teamcenter.vue?vue&type=script&lang=js"
export * from "./teamcenter.vue?vue&type=script&lang=js"
import style0 from "./teamcenter.vue?vue&type=style&index=0&id=0a74e654&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a74e654",
  null
  
)

export default component.exports