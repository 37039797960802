
import { my_info_api } from "@/api/index";

let common = {
    data() {
        return {
            windowWidth: document.documentElement.clientWidth,
            openedleft: true,
            openedcontests: false,
            isMobileFlag: false, // 响应式
            iosBut: 0,
            isFbTime: false,
            showComponentBanner: true
        }
    },
    methods: {
        reloadComponent() {
            this.showComponentBanner = false;
            this.$nextTick(() => {
                this.showComponentBanner = true;
            });
        },
        // 顶部导航栏背景色
        headerBgChange() {
            let topColor = document.getElementById("topBoxStyle");
            topColor.content = '#0d131c';
            let tileColor = document.getElementById("TileColorStyle");
            tileColor.content = '#0d131c';
            this.$store.commit("changeNormalBackgroundColor", '#0d131c');
            this.$store.commit("changeLightBackgroundColor", '#0d131c');
        },
        // zone: 域名
        // options: 以逗号分隔的选项列表，例如width、height和quality
        // sourceImage: 需要转换URL
        generateImageURL(options, sourceImage) {

            // 检查传入参数是否完整
            if (!options || !sourceImage) {
                console.error('参数 options、sourceImage 均不能为空');
                return '';
            }
            // 返回格式化后的 URL
            return `${this.extractBaseUrl(sourceImage)}/cdn-cgi/image/${options}/${this.filterUrl(sourceImage)}`;
        },
        // 用来过滤 sourceImage https
        filterUrl(url) {
            if (!url) {
                console.error('URL 不能为空');
                return '';
            }
            // 获取 `storage` 后的部分
            const storageIndex = url.indexOf('/storage/');
            if (storageIndex !== -1) {
                return url.slice(storageIndex + 1); // 从 `storage/` 开始截取
            }
            // 如果没有找到 `storage`，返回原始 URL
            return url;
        },
        // 用来获取 sourceImage https
        extractBaseUrl(url) {
            if (!url) {
                console.error('URL 不能为空');
                return '';
            }
            // 使用字符串分割方法提取
            const storageIndex = url.indexOf('/storage/');
            if (storageIndex !== -1) {
                return url.slice(0, storageIndex); // 提取 `storage` 前面的部分
            }
            // 如果没有找到 `storage`，返回完整 URL
            return url;
        },
        isBetweenMidnightAndNoon() {
            let now = new Date();
            let start = new Date();
            let end = new Date();
            // 设置凌晨1点
            start.setHours(1, 0, 0, 0); // 设置为凌晨1点，并将分钟、秒和毫秒设置为0
            // 设置下午1点
            end.setHours(13, 0, 0, 0); // 设置为下午1点，并将分钟、秒和毫秒设置为0
            // 如果当前时间在start和end之间，返回true
            if (now >= start && now < end) {
                this.$store.commit("changeFbServiceDialogVisible", true);
            } else {
                this.$store.commit("changeFbServiceDialogVisible", false);
            }
            return now >= start && now < end;
        },
        onCopyError() {
            this.errorTips(this.$t("Can not copy"))
        },
        toLiveSuppert() {
            let key = this._isMobile() ? "mobile" : "pc";
            let rule = this.$helper.get('rule');
            let url = key === 'mobile' ? rule.mobile_service_url : rule.pc_service_url;
            if (url) {
                this.platUrl(url);
            }
        },
        getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        generateWeightedRandom() {
            const highProbabilityRange = 0.7; // 0-99 占 70% 的概率
            const random = Math.random();
            if (random < highProbabilityRange) {
                // 生成 0-99 的随机数
                return Math.floor(Math.random() * 100);
            } else {
                // 生成 100-999 的随机数
                return Math.floor(Math.random() * (300 - 100 + 1)) + 100;
            }
        },
        goUrl(url) {
            this.$router.push({ path: url })
        },
        back() {
            this.$router.go(-1)
        },
        _isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
        getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }

            return "unknown";
        },
        ellipsis(str, first, last) {
            var strC = String(str);
            var len = strC.length;
            var xx = strC.substring(first, len - last);
            var str1 = strC.replace(xx, "...");
            return str1
        },
        platUrl(url) {
            if (navigator.userAgent.match(/Android/i) || /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
                window.location.href = url;
            } else {
                window.open(url, '_blank');
            }
        },
        openUrl(url) {
            if (url.startsWith('http')) {
                this.platUrl(url)
            } else {
                this.goUrl(url)
            }
        },
        downApp() {
            const ua = navigator.userAgent
            if (/iphone|ipad|ipod/i.test(ua)) {
                window.location.href = this.$helper.get("rule").ios_download_url;
                // if (this.iosBut == 0) {
                // window.location.href = this.$helper.get("rule").ios_download_url;
                // this.iosBut = 1;
                // } else {
                //     window.location.href = "https://www.pgyer.com/app/getProvisionFile";
                // }
            } else {
                let android_download_url = this.$helper.get("rule").android_download_url;
                this.platUrl(android_download_url)
            }
        },
        // 时间戳转化位日期格式
        timestampToTime(timestamp) {
            var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
            // return Y + M + D + h + m + s;
            return M + D + h + m + s;
        },
        // 时间戳转化位日期格式
        timestampToTime1(timestamp) {
            var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
            // return Y + M + D + h + m + s;
            return M + D;
        },
        // 时间戳转化位日期格式
        timestampToTime2(timestamp) {
            var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());// + ':';
            // var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
            // return Y + M + D + h + m + s;
            return h + m;
        },
        // 时间戳转化位日期格式 Y-m-d
        timestampToTime3(timestamp) {
            var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
            return Y + M + D;
        },
        // 是否滑动到底部
        scrollBottom() {
            let scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight;
            let scrollHeight = document.documentElement.scrollHeight;
            if (scrollTop + clientHeight >= scrollHeight) {
                // console.log("到底啦");
            }
        },
        // 去重，根据字段 arr: 数据  val: 字段名称
        unique(arr, val) {
            const res = new Map();
            return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1));
        },
        // 根据字段排序
        handle(property) {
            return function (a, b) {
                const val1 = a[property];
                const val2 = b[property];
                return val1 - val2;
            }
        },
        handle2(property) {
            return function (a, b) {
                const val1 = a[property];
                const val2 = b[property];
                return val2 - val1;
            }
        },
        // 开始监视底部事件
        createBottom() {
            window.addEventListener('scroll', this.scrollBottom);
        },
        // 销毁监视底部事件
        destroyedBottom() {
            window.removeEventListener("scroll", this.scrollBottom); //页面离开后销毁监听事件
        },
        clearNoNum(value) {
            value = value + ''; //转为字符串
            value = value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
            value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
            value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            value = value.replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
            if (value.indexOf(".") < 0 && value != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                value = parseFloat(value);
            }
            return value
        },
        clearIntegerNum(value) {
            value = value + ''; //转为字符串
            value = value.replace(/[^0-9.]/g, "");
            if (value != "") {
                value = parseInt(value);
            }
            return value
        },
        showLoginReg(data) {
            if (this.$route.query.modal === 'LoginRegistration') {
                return;
            }
            this.$router.replace({
                path: this.$route.path,
                query: {
                    modal: 'LoginRegistration',
                    data: 'tab-' + data
                }
            });
        },
        closeLoginReg() {
            if (this.$route.query.modal === 'LoginRegistration') {
                this.$router.replace(this.$route.path)
            }
        },
        tips(msg, title = "通知", duration = 2, icon = "⚔️") {
            const iconInit = icon == "⚔️" ? icon : `<img src="${icon}"/ >`
            this.$notify({
                customClass: "noticeClass",
                dangerouslyUseHTMLString: true,
                duration: duration * 1000 || 2000,
                message: `
                <div class="notice-bar">
                    <div class="bar-ico">${iconInit}</div>
                    <div class="flex_bd">
                        <div class="notice-tit">${this.$t(title)}</div>
                        <div class="notice-desc">${this.$t(msg)}</div>
                    </div>
                </div>
                `
            });
        },
        errorTips(msg, options) {
            let opts = {
                center: true,
                customClass: "errorMessage",
                message: this.$t(msg),
                type: "error"
            };
            if (options) {
                opts = Object.assign(opts, options);
            }
            this.$message(opts);
        },
        successTips(msg, options) {
            let opts = {
                center: true,
                customClass: "successMessage",
                message: this.$t(msg),
                type: "success"
            };
            if (options) {
                opts = Object.assign(opts, options);
            }
            this.$message(opts);
        },

        async updateUserInfo() {
            await my_info_api().then(res => {
                if (res && res.data.code == 1) {
                    this.$helper.set("userInfo", res.data.data);
                    this.$store.commit("$vuexUserInfo", res.data.data);
                }
            });
        },

        reduceUserBalance(amount) {
            if (!this.$store.state.userInfo) {
                return;
            }
            this.$store.state.userInfo.balance *= 1;
            this.$store.state.userInfo.balance -= parseFloat(amount);
            this.$store.state.userInfo.balance1 = this.$store.state.userInfo.balance.toLocaleString('pt-BR');
            this.$helper.set("userInfo", this.$store.state.userInfo);
        },
        addUserBalance(amount) {
            if (!this.$store.state.userInfo) {
                return;
            }
            this.$store.state.userInfo.balance *= 1;
            this.$store.state.userInfo.balance += parseFloat(amount);
            this.$store.state.userInfo.balance1 = this.$store.state.userInfo.balance.toLocaleString('pt-BR');
            this.$helper.set("userInfo", this.$store.state.userInfo);
        },
        isPhone() {
            if (document.body.clientWidth < 992) {
                this.isMobileFlag = true;
                this.openedleft = false;
                this.openedcontests = false;
            } else {
                this.isMobileFlag = false;
            }
            let self = this;
            window.addEventListener('resize', () => {
                window.fullWidth = document.documentElement.clientWidth;
                self.windowWidth = window.fullWidth; // 宽
                if (self.windowWidth < 992) {
                    self.isMobileFlag = true;
                    self.openedleft = false;
                    self.openedcontests = false;
                } else {
                    self.isMobileFlag = false;
                }
            });
        },
        /**
         * 本地化数字
         * @param {float} val
         * @returns
         */
        numFormat(val, isflag, minimumFractionDigits = this.$helper.get("amount_decimal_point") || 4, maximumFractionDigits = minimumFractionDigits) {
            let selfUnit = ''
            if (isflag) selfUnit = this.$store.state.siteUnit;
            if (isNaN(val)) return selfUnit + val;
            return selfUnit + parseFloat(val).toLocaleString('en', { minimumFractionDigits, maximumFractionDigits });
        },
        noDecimalsFormat(num, isflag) {
            let val = num.split('/')
            let selfUnit = ''
            if (isflag) {
                selfUnit = this.$store.state.siteUnit;
            }
            return selfUnit + val[0].replace(/,(.*)/, '') + '/' + val[1].replace(/,(.*)/, '')
        },
        formatNumber(number) {
            let cache = parseFloat(number).toFixed(2)
            return cache;
        },
        // 解决iOS下select需要点击2次的问题
        elSelectOnFocus() {
            document.activeElement.blur();
        },
    },
    onLoad() { },
    mounted() { },
    created() {
        this.isPhone();
    },
    watch: {
        $route() {
            this.reloadComponent(); // 每次路由变化时重新加载
        }
    }
}

export default common
