
import { h } from "vue";
import helper from "../helper";
import { login_get_third_login_url_api } from "@/api/user";
class Telegram {
    thirdInfo = null;
    telegramApp = 0;
    constructor(thirdInfo){
        this.thirdInfo = thirdInfo;
        this.telegramApp = helper.get('telegramApp');
        this.init();
    }
    // 完成注册
    login(callback){
        const bot_id = helper.get('telegram:' + this.thirdInfo.id);
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.ready();
            callback && callback(window.Telegram.WebApp.initData);
        } else if (window.Telegram.Login) {
            window.Telegram.Login.auth({ bot_id: bot_id, request_access: 'write', embed: 1 }, (user) => {
                if (!user) {
                    callback && callback(false);
                } else {
                    callback && callback(JSON.stringify(user));
                }
            });
        } else {
            console.error('Telegram API not found');
        }
    }

    init() {
        if (window.Telegram) {
            return;
        }
        const bot_id = helper.get('telegram:' + this.thirdInfo.id);
        if (!bot_id) {
            login_get_third_login_url_api({ id : this.thirdInfo.id, href: location.origin }).then(res => {
                if (res && res.data.code == 1) {
                    helper.set('telegram:' + this.thirdInfo.id, res.data.data.bot_id)
                }
            });
        }
        helper.loadScript(this.telegramApp ? 'https://telegram.org/js/telegram-web-app.js' : 'https://telegram.org/js/telegram-widget.js')
        .then(() => {})
        .catch((error) => {
            // console.error('Error loading script:', error);
        });
    }
  }
  
  
  export default Telegram;