import { render, staticRenderFns } from "./pdd.vue?vue&type=template&id=b38ed3b4&scoped=true"
import script from "./pdd.vue?vue&type=script&lang=js"
export * from "./pdd.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/pdd.less?vue&type=style&index=0&id=b38ed3b4&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b38ed3b4",
  null
  
)

export default component.exports